import "core-js/es/string/pad-end";
import "core-js/es/math/imul"
import "core-js/es/array/from"
import "core-js/es/array/reduce"
import "core-js/es/array/some"
import "core-js/es/array/fill"
import "core-js/es/array/includes"
import "core-js/es/array/flat"
import "core-js/es/array/flat-map"
import "core-js/es/array/find"
import "core-js/es/array/for-each"
import "core-js/es/array/find-index"
import "core-js/es/object/assign"
import "core-js/es/object/entries"
import "core-js/es/object/values"
import "core-js/es/string/includes"
import "core-js/es/promise"

if (!('remove' in (Element.prototype as HTMLElement))) {
    Element.prototype.remove = function() {
        if (this.parentNode) {
            this.parentNode.removeChild(this)
        }
    }
}